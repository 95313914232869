import BasePage from '../../../../../../components/table_page';

export default {
  name: 'detail',
  extends: BasePage,
  props: {
    paramsProps: Object,
  },
  data() {
    return {};
  },
  created() {
    this.getConfigList('tpm_cost_budget_detail');
  },
};
