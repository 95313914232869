import request from '../../../../../../utils/request';
import Form, { formCreate } from '../../../../../../components/form';
import textView from './text-view';
import selectBudgetFee from './selectBudgetFee';

formCreate.component('textView', textView);
formCreate.component('selectBudgetFee', selectBudgetFee);

export default {
  name: 'adjust-edit',
  extends: Form,
  props: {
    functionCode: String,
    modalType: String,
    rowId: String,
    feeBudgetCode: String,
    // fieldChange: Function,
  },
  data() {
    return {
      formFunctionCode: this.functionCode,
      buttons: {
        submit: false,
        close: false,
      },
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  watch: {
    rowId() {
      this.getDetails();
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'feeBudgetCode' && this.modalType === 'right') {
        v.type = 'selectBudgetFee';
        v.props = {
          code: this.feeBudgetCode,
        };
      } else if (v.field === 'changeAmount' || v.field === 'businessRemarks') {
        v.props = {
          ...v.props,
          disabled: true,
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      this.hiddenFields(this.modalType !== 'left', ['changeAmount', 'businessRemarks']);

      const changeAmount = this.getRule('changeAmount');
      const canUseAmount = this.getRule('canUseAmount');
      const feeBudgetCode = this.getRule('feeBudgetCode');

      changeAmount.on.change = (e) => {
        const num = e ? Number(canUseAmount.value) - Number(e) : Number(canUseAmount.value);
        this.setValue({ afterAdjustAmount: num });
        this.$emit('fieldChange', e);
      };
      feeBudgetCode.on.change = (e) => {
        this.$emit('fieldChange', e, 'replace');
      };

      if (this.rowId) {
        this.getDetails();
      }
    },
    getDetails() {
      console.log(this.rowId);
      request
        .get('/tpm/tpmFeeBudgetController/query', {
          id: this.rowId,
        })
        .then((res) => {
          if (res.success) {
            const data = res.result;
            data.afterAdjustAmount = data.canUseAmount;
            this.setValue(data);
          }
        });
    },
  },
};
