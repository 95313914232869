import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      formFunctionCode: 'tpm_cost_budget_form',
    };
  },
  methods: {
    // 类型设置表单内容
    resetFormField(type) {
      let allFields = [];
      let fields = [];
      switch (type) {
        case 'department_budget':
          allFields = ['customerName', 'customerCode', 'channel', 'terminalCode'];
          fields = ['orgName', 'orgCode'];
          break;
        case 'customer_budget':
          allFields = ['orgName', 'orgCode', 'channel', 'terminalCode'];
          fields = ['customerName', 'customerCode'];
          break;
        case 'channel_budget':
          allFields = ['customerName', 'customerCode', 'terminalCode'];
          fields = ['orgName', 'orgCode', 'channel'];
          break;
        case 'terminal_budget':
          allFields = ['orgName', 'orgCode', 'channel'];
          fields = ['customerName', 'customerCode', 'terminalCode'];
          break;
        default:
          allFields = [
            'orgName',
            'orgCode',
            'customerName',
            'customerCode',
            'channel',
            'terminalCode',
          ];
          break;
      }

      this.hiddenFields(true, allFields);
      this.hiddenFields(false, fields);
    },
    // 设置月份列表，
    setMonthList(type) {
      let options = [];
      switch (type) {
        case '1':
          options = [
            {
              label: '一月',
              value: '1',
            },
            {
              label: '二月',
              value: '2',
            },
            {
              label: '三月',
              value: '3',
            },
          ];
          break;
        case '2':
          options = [
            {
              label: '四月',
              value: '4',
            },
            {
              label: '五月',
              value: '5',
            },
            {
              label: '六月',
              value: '6',
            },
          ];
          break;
        case '3':
          options = [
            {
              label: '七月',
              value: '7',
            },
            {
              label: '八月',
              value: '8',
            },
            {
              label: '九月',
              value: '9',
            },
          ];
          break;
        case '4':
          options = [
            {
              label: '十月',
              value: '10',
            },
            {
              label: '十一月',
              value: '11',
            },
            {
              label: '十二月',
              value: '12',
            },
          ];
          break;
        default:
          break;
      }

      this.updateRules({
        budgetMonth: {
          options,
        },
      });
    },
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'orgName') {
        v.restful = '/mdm/mdmOrgController/select';
        v.restfulParams = { enableStatus: '009' };
        // v.refresh = true;
        v.props = {
          ...v.props,
          remoteParams: 'orgName',
          remote: true,
          filterable: true,
        };
        v.optionsKey = {
          label: 'orgName',
          value: 'orgName',
        };
      } else if (v.field === 'customerName') {
        v.restful = '/mdm/mdmCustomerMsgController/findCustomerSelectList';
        v.restfulParams = { enableStatus: '009', lockState: '009' };
        // v.refresh = true;
        v.props = {
          ...v.props,
          remoteParams: 'customerName',
          remote: true,
          filterable: true,
        };
        v.optionsKey = {
          label: 'customerName',
          value: 'customerName',
        };
      } else if (v.field === 'terminalCode') {
        v.restful = '/mdm/mdmTerminalController/customerTerminalList';
        v.restfulParams = { enableStatus: '009' };
        // v.refresh = true;
        v.props = {
          ...v.props,
          remoteParams: 'terminalName',
          remote: true,
          filterable: true,
        };
        v.optionsKey = {
          label: 'terminalName',
          value: 'terminalCode',
        };
      } else if (v.field === 'productCode') {
        v.restful = '/mdm/mdmProductController/pageList';
        v.restfulParams = { enableStatus: '009' };
        // v.refresh = true;
        v.props = {
          ...v.props,
          remoteParams: 'productName',
          remote: true,
          filterable: true,
        };
        v.optionsKey = {
          label: 'productName',
          value: 'productCode',
        };
      } else if (v.field === 'budgetSubjectsName') {
        v.restful = '/tpm/tpmBudgetSubjectsController/list';
        v.restfulParams = { enableStatus: '009' };
        // v.refresh = true;
        v.props = {
          ...v.props,
          remoteParams: 'budgetSubjectsName',
          remote: true,
          filterable: true,
        };
        v.optionsKey = {
          label: 'budgetSubjectsName',
          value: 'budgetSubjectsName',
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      const feeBudgetType = this.getRule('feeBudgetType');
      const budgetQuater = this.getRule('budgetQuater');
      const orgName = this.getRule('orgName');
      const customerName = this.getRule('customerName');
      const budgetSubjectsName = this.getRule('budgetSubjectsName');

      feeBudgetType.on.change = (e) => {
        this.resetFormField(e);
      };

      budgetQuater.on.change = (e) => {
        this.setMonthList(e);
        this.setValue({ budgetMonth: '' });
      };

      orgName.on.change = (e) => {
        const item = orgName.options.filter((v) => v.orgName === e)[0];
        this.setValue({ orgCode: item.orgCode });
      };

      customerName.on.change = (e) => {
        const item = customerName.options.filter((v) => v.customerName === e)[0];
        this.setValue({ customerCode: item.customerCode });
      };

      budgetSubjectsName.on.change = (e) => {
        const item = budgetSubjectsName.options.filter((v) => v.budgetSubjectsName === e)[0];
        this.setValue({ budgetSubjectsCode: item.budgetSubjectsCode });
      };

      if (this.formConfig.code === 'add') {
        this.resetFormField();
      }

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/tpm/tpmFeeBudgetController/query', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            if (res.success) {
              this.setValue(res.result);
              this.resetFormField(res.result.feeBudgetType);
              this.setMonthList(res.result.budgetQuater);
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/tpm/tpmFeeBudgetController/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmFeeBudgetController/update';
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
